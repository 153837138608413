export const colorsTheme = {
  white: '#FFFFFF',
  black: '#FFFFFF',
  danger: '#F2383A',

  'gradient-deg': '71deg',
  'gradient-primary': '#000',
  'gradient-secondary': '#9F1B1A',

  'primary-color': '#B02A37',
  'secondary-color': '#842029',
  'auxiliary-color': '#F2383A',

  'bg-color': '#212529',
  'bg-secondary-color': '#121212',
  'bg-auxiliary-color': '#343A40',

  'bg-circle-color': '#FFFFFF',
  'bg-circle-hover': '#b02a37',
  'bg-circle-text-color': '#000',
  'bg-circle-text-color-hover': '#FFFFFF',

  'filter-button-background': '#b02a37',
  'filter-button-color': '#FFFFFF',
  'filter-button-border': '#b02a37',
  'entry-message-color': '#b02a37',

  'bg-modal-color': '#343A40',

  'dark-color': '#3F3F3F',

  'text-primary': '#FFFFFF',
  'text-secondary': '#FFFFFF',
  'text-tertiary': '#FFFFFF',
  'text-gray': '#4F4F4F',

  'input-color': '#FFFFFF',
  'bg-input-color': '#E9ECEF',
  'info-color': '#6c757d',
  'disabled-color': '#6c757d',
  'card-color': '#495057',
  'modal-border-color': '#ADB5BD',
  'border-color': '#FFFFFF',

  'echeck-label-color': '#000',
  'echeck-border-color': '#D2D2D2',
  'echeck-text-color': '#000',
  'echeck-bar-color1': '#B02A37',
  'echeck-bar-color2': '#B02A37',
};
