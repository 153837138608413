import { Input } from '../../../../components/Input'
import { useForm, useFieldArray  } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Box } from '../../Box'
import { ButtonAdd as Button, DocumentTypeContainer, DocumentTypeContent, InputContainer, RepresentativeFormContent, RepresentativeFormInputs } from './style'
import { useRegister } from '../../../../hooks/useRegister'
import { formatCnpjCpf, formatPhone } from '../../../../utils/formatter'
import { toast } from 'react-toastify'
import moment from 'moment'
import { validarCPF } from '../../../../services/validarCPF'
import { calcularIdade } from '../../../../services/calcularIdade'
import { useState } from 'react'
import { Select } from '../../../../components/select'
import { birthState } from '../../../../utils/birthState'
import { genderOptions } from '../../../../utils/genders'
import { maritalStatusOptions } from '../../../../utils/maritalStatus'

const CampanyDataFieldSchema = z.object({
  name: z.string().regex(
    /^[a-zA-Z\u00C0-\u017F.]+\s[a-zA-Z\u00C0-\u017F.]+\s*([a-zA-Z\u00C0-\u017F.]+\s*)*$/,
    {
      message: 'Digite pelo menos dois nomes separados por espaço'
    }
  ),
  motherName: z.string().regex(
    /^[a-zA-Z\u00C0-\u017F.]+\s[a-zA-Z\u00C0-\u017F.]+\s*([a-zA-Z\u00C0-\u017F.]+\s*)*$/,
    {
      message: 'Digite pelo menos dois nomes separados por espaço',
    }
  ),
  birthDate: z.string().refine((value) => {
    const dataNascimento = new Date(value);
    const idade = calcularIdade(dataNascimento);

    return (idade >= 18 && idade <= 110);
  }, { message: 'A data de nascimento não é válida' }),
  cpf: z
  .string()
  .min(1, 'O campo CPF é obrigatório.')
  .max(18, 'O campo CPF aceita no máximo 11 digitos.')
  .refine((value) => validarCPF(value), {
    message: 'CPF inválido',
  }),
  email: z.string().email(),
  phone: z
    .string()
    .refine((value) => {
      const phone = value.replace(/\D/g, '')

      if (phone.length < 10 || phone.length > 11) {
        return false
      }

      return true
    }, 'Telefone inválido.'),
  gender: z.any(),
  maritalStatus: z.any(),
  birthState: z.any(),
  birthCity: z.string(),
  documentFront: z.any(),
  documentBack: z.any(),
  documentType: z.string(),
});

const CampanyDataFormSchema = z.object({
  dynamicFields: z.array(CampanyDataFieldSchema),
});

export const RepresentativeForm = () => {
  const [documentType, setDocumentType] = useState([
    { value: 'RG' },
  ]);

  const { postRepresentativeFormPj, setStep, loading } = useRegister();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    resolver: zodResolver(CampanyDataFormSchema),
    defaultValues: {
      dynamicFields: [
        {
          name: '',
          motherName: '',
          birthDate: '',
          cpf: '',
          email: '',
          phone: '',
          gender: '',
          maritalStatus: '',
          birthState: '',
          birthCity: '',
          documentFront: '',
          documentBack: '',
          documentType: 'RG',
        },
      ],
    },
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "dynamicFields",
  });

  const handleChangeDocumentType = (value, itemIndex) => {
    setValue(`dynamicFields[${itemIndex}].documentType`, value)
    setDocumentType(state => state.map((item, index) => index === itemIndex ? { ...item, value: value } : item))
  };

  const validateFile = (name, value) => {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

    if (!value) {
      setError(name, { type: 'manual', message: 'Por favor, escolha um arquivo.' });
      return false;
    }

    if (!allowedTypes.includes(value.type)) {
      setError(name, { type: 'manual', message: 'Tipo de arquivo não permitido.' });
      return false;
    }

    return true;
  };

  const addRepresentative = () => {
    if (fields.length < 5) {
      append({
        name: '',
        motherName: '',
        birthDate: '',
        cpf: '',
        email: '',
        phone: '',
        gender: '',
        maritalStatus: '',
        birthCity: '',
        birthState: '',
        documentFront: '',
        documentBack: '',
        documentType: 'RG',
      })

      setDocumentType(state => [...state, { value: 'RG' }])
    } else {
      toast.warning('Limite de representantes atingido.')
    }
  };

  const removeRepresentative = (itemIndex) => {
    if (fields.length > 1) {
      remove(itemIndex)

      setDocumentType(state => state.filter((_, index) => index != itemIndex))
    } else {
      toast.warning('É necessário pelo menos 1 representante legal.')
    }
  }

  const handleNext = async (data) => {
    const items = data.dynamicFields

    let validFornt = false
    let validBack = false

    items.forEach((item, index) => {
      if (item.documentType === 'CNH') {
        item.documentBack = item.documentFront
      }

      validFornt = validateFile(`dynamicFields[${index}].documentFront`, item.documentFront.item(0))
      validBack = validateFile(`dynamicFields[${index}].documentBack`, item.documentBack.item(0))
    })

    if (validFornt && validBack) {
      await postRepresentativeFormPj(items);
    }
  };

  const handleBack = () => {
    setStep(2);
  };

  return (
    <Box
      handleSubmit={handleSubmit}
      submitEvent={handleNext}
      title="Dados do representante legal"
      secondDescription="É importante que antes de enviar os documentos verifique alguns pontos como: "
      pointList={[
        'Evite luzes que podem impedir a visibilidade do documento.',
        'Remova acessórios como capas plásticas de proteção.',
        'Rasuras e partes danificadas podem impedir a leitura do documento.',
        'Em caso de documentos digitais é necessário que o mesmo contenha o QR para validação.',
        'Evite documentos como RG digitalizados, bata uma foto legível do documento e o envie.',
        'Evite mandar frente e verso juntos no mesmo arquivo.',
        'Verifique a validade do seu documento de RG, aceitamos o documento com até 10 anos.',
        'Em caso de CNH, aceitamos em formato digital ou com o documento aberto, mostrando frente e verso',
      ]}
      buttonOne="Voltar"
      buttonTwo="Prosseguir"
      marginLeft="l"
      handleBack={handleBack}
    >
      <RepresentativeFormInputs>
        <Button type='button' onClick={addRepresentative}>Adicionar Representante</Button>

        {fields.map((field, index) => (
          <RepresentativeFormContent key={index}>
            <span>Representante Legal {index + 1}</span>

            <InputContainer>
              <Input
                type="text"
                label="Nome Completo"
                error={errors?.dynamicFields?.[index]?.name && errors.dynamicFields[index].name.message}
                isRequired
                register={register(`dynamicFields[${index}].name`, {
                  required: true,
                })}
              />

              <Input
                type="text"
                label="Nome Completo da Mãe"
                error={errors?.dynamicFields?.[index]?.motherName && errors?.dynamicFields?.[index]?.motherName.message}
                isRequired
                register={register(`dynamicFields[${index}].motherName`, {
                  required: true,
                })}
              />

              <Input
                type="date"
                label="Data de Nascimento"
                max={moment(new Date()).format("YYYY-MM-DD")}
                error={errors?.dynamicFields?.[index]?.birthDate && errors?.dynamicFields?.[index]?.birthDate.message}
                isRequired
                register={register(`dynamicFields[${index}].birthDate`, {
                  required: true,
                })}
              />

              <Input
                type="mask"
                label="CPF"
                placeholder="000.000.000-00"
                mask="999.999.999-99"
                error={errors?.dynamicFields?.[index]?.cpf && errors?.dynamicFields?.[index]?.cpf.message}
                isRequired
                register={register(`dynamicFields[${index}].cpf`, {
                  required: true,
                  maxLength: 11,
                  onChange: (e) => formatCnpjCpf(e.target.value)
                })}
              />

              <Select
                label="Genero"
                error={errors?.dynamicFields?.[index]?.gender && errors?.dynamicFields?.[index]?.gender.message}
                isRequired
                register={register(`dynamicFields[${index}].gender`, {
                  required: true,
                })}
                options={genderOptions}
              />


              <Select
                label="Estado Civil"
                error={errors?.dynamicFields?.[index]?.maritalStatus && errors?.dynamicFields?.[index]?.maritalStatus.message}
                isRequired
                register={register(`dynamicFields[${index}].maritalStatus`, {
                  required: true,
                })}
                options={maritalStatusOptions}
              />

              <Select
                label="Estado de Nascimento"
                error={errors?.dynamicFields?.[index]?.birthState && errors?.dynamicFields?.[index]?.birthState.message}
                isRequired
                register={register(`dynamicFields[${index}].birthState`, {
                  required: true,
                })}
                options={birthState}
              />

              <Input
                type="text"
                label="Cidade de Nascimento"
                error={errors?.dynamicFields?.[index]?.birthCity && errors?.dynamicFields?.[index]?.birthCity.message}
                isRequired
                register={register(`dynamicFields[${index}].birthCity`, {
                  required: true,
                })}
              />

              <Input
                type="text"
                label="E-mail"
                error={errors?.dynamicFields?.[index]?.email && errors?.dynamicFields?.[index]?.email.message}
                isRequired
                register={register(`dynamicFields[${index}].email`, {
                  required: true,
                })}
              />

              <Input
                type="text"
                label="Telefone"
                maxLength={15}
                error={errors?.dynamicFields?.[index]?.phone && errors?.dynamicFields?.[index]?.phone.message}
                isRequired
                onlyNumber
                register={register(`dynamicFields[${index}].phone`, {
                  required: true,
                  onChange: (e) => e.target.value = formatPhone(e.target.value),
                })}
              />

            </InputContainer>

            <DocumentTypeContainer>
                <span>Tipo de documento</span>

                <DocumentTypeContent>
                <label>
                  <input
                    type="radio"
                    value="RG"
                    checked={documentType[index].value === 'RG'}
                    onChange={(e) => handleChangeDocumentType(e.target.value, index)}
                  />
                  RG
                </label>

                <label>
                  <input
                    type="radio"
                    value="CNH"
                    checked={documentType[index].value === 'CNH'}
                    onChange={(e) => handleChangeDocumentType(e.target.value, index)}
                  />
                  CNH
                </label>
                </DocumentTypeContent>
            </DocumentTypeContainer>

            <InputContainer>
              {
                documentType[index].value === 'RG'
                ? (
                  <>
                    <Input
                      type="file"
                      accept=".jpg, .jpeg, .png, .pdf"
                      label="RG - Somente Frente"
                      description="Evite enviar o documento digitalizado"
                      error={errors?.dynamicFields?.[index]?.documentFront && errors?.dynamicFields?.[index]?.documentFront.message}
                      isRequired
                      register={register(`dynamicFields[${index}].documentFront`, {
                        required: true,
                      })}
                    />

                    <Input
                      type="file"
                      accept=".jpg, .jpeg, .png, .pdf"
                      label="RG - Somente Verso"
                      description="Evite enviar o documento digitalizado"
                      error={errors?.dynamicFields?.[index]?.documentBack && errors?.dynamicFields?.[index]?.documentBack.message}
                      isRequired
                      register={register(`dynamicFields[${index}].documentBack`, {
                        required: true,
                      })}
                    />
                  </>
                ) : (
                  <>
                    <Input
                      type="file"
                      accept=".jpg, .jpeg, .png, .pdf"
                      label="CNH"
                      error={errors?.dynamicFields?.[index]?.documentFront && errors?.dynamicFields?.[index]?.documentFront.message}
                      isRequired
                      register={register(`dynamicFields[${index}].documentFront`, {
                        required: true,
                      })}
                    />
                  </>
                )
              }
            </InputContainer>

            <Button type='button' align="flex-start" onClick={() => removeRepresentative(index)}>Remover Representante</Button>
          </RepresentativeFormContent>
        ))}
      </RepresentativeFormInputs>
    </Box>
  );
};
