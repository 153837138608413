import { ToastContainer } from "react-toastify"
import { useRegister } from "../../../hooks/useRegister"
import { CompleteRegister } from "../CompleteRegister"
import { AddressForm } from "./AddressForm"
import { CompanyDataForm } from "./CompanyDataForm"
import { DocumentsForm } from "./DocumentsForm"
import { RepresentativeForm } from "./RepresentativeForm"
import { RegisterPJContainer } from "./styles"
import { Resume } from "./Resume"

export const RegisterPJ = () => {
  const { success, step } = useRegister();

  return (
    <>
      {
        !success ? (
          <RegisterPJContainer>
            {step === 1 && <CompanyDataForm />}
            {step === 2 && <AddressForm />}
            {step === 3 && <RepresentativeForm />}
            {step === 4 && <DocumentsForm />}
            {step === 5 && <Resume />}
          </RegisterPJContainer>
        ) : <CompleteRegister />
      }
      <ToastContainer limit={2} />
    </>
  )
}