import { apiV1 } from "../lib/axios";
import { toast } from "react-toastify";

export const sendTransactionMfa = async () => {
  await apiV1.post("/mfa")
      .catch((err) => {
        toast.error("Erro ao enviar o MFA",err);
        return;
      });
    return
}