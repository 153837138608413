import { Input } from '../../../components/Input';
import { Box } from '../../../components/box';
import { PageHeader } from '../../../components/page-header';
import { TbReceipt } from 'react-icons/tb';
import { TbKey } from 'react-icons/tb';
import { FiShare2 } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { useTicket } from '../../../hooks/useTicket';
import BeatLoader from 'react-spinners/BeatLoader';
import { css } from '@emotion/react';
import { useEffect } from 'react';
import moment from 'moment';
import { useAuth } from '../../../hooks/useAuth';
import { getImage } from '../../../services/getAssets';
import { formatCnpjCpf } from '../../../utils/formatter';
import { FilterItemMfaGeneralBoleto } from '../../../components/E-check/style';
import './styles.css';
import { sendTransactionMfa } from '../../../services/mfa.service';
import { toast } from 'react-toastify';

export function PagarBoleto() {
  const [codBoleto, setCodBoleto] = useState('');
  const [codBoletoError, setCodBoletoErro] = useState('');
  const {
    ticketLoader,
    setTicketLoader,
    step,
    boleto,
    getTicket,
    setStep,
    payTicket,
  } = useTicket();
  const [mfaCode, setMfaCode] = useState('');
  const { user, getProfile } = useAuth();

  async function handleProceed() {
    setCodBoletoErro('');
    setTicketLoader(true);

    try {
      if (!/^[\d.\s]+$/.test(codBoleto)) {
        setCodBoletoErro('O código do boleto deve conter apenas números.');
        setTicketLoader(false);
        return;
      }

      if (codBoleto.length < 47) {
        setCodBoletoErro(
          'O código do boleto é muito curto. Verifique e tente novamente.'
        );
        setTicketLoader(false);
        return;
      }
      const boletoResult = await getTicket(codBoleto);
      if (!boletoResult || !boletoResult.barcode || boletoResult.value <= 0) {
        setCodBoletoErro(
          'Boleto inválido. Verifique as informações e tente novamente.'
        );
        setTicketLoader(false);
        return;
      }
      if (
        !boletoResult.dueDate ||
        (typeof boletoResult.dueDate === 'string' &&
          boletoResult.dueDate.trim() === '')
      ) {
        setCodBoletoErro(
          'Data inválida ou não fornecida. Por favor, preencha a data.'
        );
        setTicketLoader(false);
        return;
      }
      await sendTransactionMfa();
    } catch (error) {
      console.error('Erro ao validar o boleto:', error);
      setCodBoletoErro('Erro ao validar o boleto. Tente novamente.');
    } finally {
      setTicketLoader(false);
    }
  }

  const handleBoletoConfirmation = async (otp) => {
    if (!otp) {
      return toast.error('O código MFA não pode estar vazio');
    }
    await handleConfirmPayment(otp);
  };

  function handleConfirmPayment(otp) {
    const data = {
      dueDate: moment().format(),
      type: 'Boleto',
      payerName: user.name,
      payerTaxNumber: user.taxNumber,
      payerBank: user.account.bank,
      payerBankBranch: user.account.bankBranch,
      payerBankAccount: user.account.bankAccount,
      payerBankAccountDigit: user.account.bankAccountDigit,
      identifier: boleto.identifier,
      value: boleto.value,
      barCode: boleto.barcode,
      url_logo: getImage('logo-preto.png'),
      otp,
    };

    return payTicket(data);
  }

  const [isDisabled, setIsDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const handleClick = async () => {
    await sendTransactionMfa();
    setIsDisabled(true);
    setCountdown(40);

    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(interval);
          setIsDisabled(false);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (!user.id) {
      getProfile();
    }
  }, [user]);

  useEffect(() => {
    setStep(1);
  }, []);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-top: 3em;
  `;

  return (
    <div className="pagar-boleto-container">
      <PageHeader
        title="Pagar boleto"
        pages={[
          { name: 'Boleto', link: '/pagina-boleto' },
          { name: 'Pagar Boleto', link: '' },
        ]}
      />

      {step === 1 && !ticketLoader && (
        <Box
          title="Pagar Boleto"
          description="Digite o código de barras do boleto que você deseja pagar"
        >
          <div className="step1-content">
            <Input
              type="text"
              label="Boleto bancário"
              icon={<TbReceipt size={18} />}
              value={codBoleto}
              onChange={setCodBoleto}
              error={codBoletoError}
              isRequired
              maxLength={65}
            />

            <div className="step-buttons">
              <NavLink to="/pagina-boleto" className="step-button">
                Cancelar
              </NavLink>
              <button
                className="step-button step-button-red"
                onClick={handleProceed}
              >
                Prosseguir
              </button>
            </div>
          </div>
        </Box>
      )}

      {step === 2 && !ticketLoader && (
        <Box title="Confirmar dados de Boleto">
          <div className="step2-content">
            <div className="step2-content-infos">
              <div className="step2-content-info">
                <div className="info-item">
                  Valor à pagar
                  <span>
                    R$:{' '}
                    {Number(boleto.value)
                      .toFixed(2)
                      .toString()
                      .replace('.', ',')}
                  </span>
                </div>

                <div className="info-item">
                  Código de Boleto
                  <span>{boleto.barcode}</span>
                </div>
              </div>

              <div className="step2-content-info">
                <div className="info-item">
                  Valor original
                  <span>
                    R$:{' '}
                    {Number(boleto.totalValue)
                      .toFixed(2)
                      .toString()
                      .replace('.', ',')}
                  </span>
                </div>

                <div className="info-item">
                  Valor do desconto
                  <span>
                    R$:{' '}
                    {boleto.discountValue
                      ? Number(boleto.discountValue)
                          .toFixed(2)
                          .toString()
                          .replace('.', ',')
                      : Number(0).toFixed(2).toString().replace('.', ',')}
                  </span>
                </div>

                {boleto.interestValue || boleto.fineValue ? (
                  <>
                    {boleto.interestValue && (
                      <div className="info-item">
                        Valor do juros
                        <span>
                          R$:{' '}
                          {Number(boleto.interestValue)
                            .toFixed(2)
                            .toString()
                            .replace('.', ',')}
                        </span>
                      </div>
                    )}

                    {boleto.fineValue && (
                      <div className="info-item">
                        Valor da multa
                        <span>
                          R$:{' '}
                          {Number(boleto.fineValue)
                            .toFixed(2)
                            .toString()
                            .replace('.', ',')}
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="step2-content-info">
                <div className="info-item">
                  Instituição de Destino
                  <span>
                    {boleto.bankCode ? `${boleto.bankCode} -` : ''}{' '}
                    {boleto.bankName}
                  </span>
                </div>

                <div className="info-item">
                  CPF/CNPJ do Favorecido
                  <span>
                    {boleto.taxNumberBeneficiary
                      ? formatCnpjCpf(boleto.taxNumberBeneficiary)
                      : 'Não informado.'}
                  </span>
                </div>
              </div>
            </div>

            <div className="step2-input-container">
              <Input
                type="text"
                label="Código de confirmação"
                placeholder="Insira o código MFA"
                icon={<TbKey size={18} />}
                value={mfaCode}
                onChange={setMfaCode}
                isRequired
              />
            </div>
            <FilterItemMfaGeneralBoleto>
              {isDisabled && (
                <p>
                  Você pode solicitar um novo
                  <br /> código em 0:
                  {countdown} segundos
                </p>
              )}
              <button onClick={handleClick} disabled={isDisabled}>
                Novo Código
              </button>
            </FilterItemMfaGeneralBoleto>

            <div className="step-buttons step2-buttons">
              <button
                className="step-button"
                onClick={() => {
                  setStep(1);
                  setCodBoleto('');
                }}
              >
                Voltar
              </button>
              <button
                className="step-button step-button-red"
                onClick={() => handleBoletoConfirmation(mfaCode)}
              >
                Confirmar Pagamento
              </button>
            </div>
          </div>
        </Box>
      )}

      {step === 3 && !ticketLoader && (
        <Box
          icon="circleCheck"
          title="Pagamento de Boleto realizado com sucesso"
        >
          <div className="step3-content">
            <div className="step3-content-infos">
              <div className="step3-content-info">
                <div className="info-item">
                  Valor
                  <span>
                    R$:{' '}
                    {Number(boleto.value)
                      .toFixed(2)
                      .toString()
                      .replace('.', ',')}
                  </span>
                </div>

                <div className="info-item">
                  Código de Boleto
                  <span>{boleto.barcode}</span>
                </div>
              </div>

              <div className="step3-content-info">
                <div className="info-item">
                  Instituição de Destino
                  <span>
                    {boleto.bankCode} - {boleto.bankName}
                  </span>
                </div>
              </div>
            </div>

            <div className="step-buttons step3-buttons">
              <NavLink
                className="step-button"
                to="/comprovante"
                target="_blank"
              >
                Compartilhar comprovante
                <FiShare2 size={16} />
              </NavLink>
            </div>
          </div>
        </Box>
      )}

      {ticketLoader && (
        <div className="loader-div">
          <BeatLoader
            color={'var(--secondary-color)'}
            loading={ticketLoader}
            css={override}
            size={20}
          />
        </div>
      )}
    </div>
  );
}
